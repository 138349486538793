import { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";

import { auth as clientAuth } from "~/firebase.client";

// FIXME proponuje z tego zrobic UserProvider i zapisywac user w Context
export const useUserUid = () => {
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    onAuthStateChanged(clientAuth, async (u) => {
      if (u) {
        setUid(u.uid);
      } else {
        setUid(null);
      }
    });
  }, []);

  return uid;
};
